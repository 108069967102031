@import '../core/colors.sass'
	
.error-code
    font-size: 18em
    font-weight: bold
    text-align: center
    text-shadow: -2px -2px 6px rgba(0,0,0,0.4)
    -webkit-background-clip: text
    -moz-background-clip: text
    background-clip: text
    color: lighten($background, 3%)
p
    text-align: center
    color: lighten($background, 40%)
    font-size: 2em
.page-wrapper
    min-height: 0%